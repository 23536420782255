module.exports = {
  cookieConsent:
    'Um die Webseite und Services für Sie zu optimieren, werden Cookies verwendet. Durch die weitere Nutzung der Webseite stimmen Sie dem zu.',
  cookieConsentLink: 'Verwendung',
  JobPostingMetaDateAndDuration: '{{date}} for {{duration}}',
  JobPostingMetaLocation: 'Standort: {{jobLocation}}',
  JobPostingMetaPPH: 'Preis pro Stunde: {{pricePerHour}}',
  JobPostingMetaPPY: 'Preis pro Jahr: {{pricePerYear}}',
  JobPostingMetaCommission: 'Kommission: {{commission}}',
  JobPostingMetaReferredBy: 'Referriert von {{referredBy}}',
  'Company / Project / Client Description':
    'Beschreibung von Firma / Projekt / Kunde',
  'Your skills': 'Deine Skills',
  'Benefits of working with Company / Project / Client':
    'Vorteile bei Firma / Projekt / Kunde zu arbeiten',
  'What you will do at the company': 'Deine Aufgabengebiete',
  specialistGroup: 'Select the group of the specialist: ',
  getStarted: 'Loslegen',
  navigation: {
    jobs: 'Arbeitsplätze',
    specialists: 'Spezialisten',
    references: 'Verweise',
    aboutUs: 'Über uns Us',
    blog: 'Blog'
  }
}
