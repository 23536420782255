module.exports = {
  cookieConsent:
    'In order to optimize the website, user experience and services for you, cookies are being used. By continuing to use the website, you consent to the use of cookies.',
  cookieConsentLink: 'Agree',
  JobPostingMetaDateAndDuration: '{{date}} for {{duration}}',
  JobPostingMetaLocation: 'Location: {{jobLocation}}',
  JobPostingMetaPPH: 'Price per Hour: {{pricePerHour}}',
  JobPostingMetaPPY: 'Price per Year: {{pricePerYear}}',
  JobPostingMetaCommission: 'Commission: {{commission}}',
  JobPostingMetaReferredBy: 'Referred by {{referredBy}}',
  specialistGroup: 'Select the group of the specialist: ',
  getStarted: 'Get Started',
  navigation: {
    jobs: 'Jobs',
    specialists: 'Specialists',
    references: 'References',
    aboutUs: 'About Us',
    blog: 'Blog'
  }
}
